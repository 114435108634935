import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const SubscriptionCard = ({ item }) => {
  const { setPlan } = useContext(AppContext);
  const navigate = useNavigate();
  const goToOutro = () => {
    setPlan({
      months: parseFloat(item.months),
      emi: parseFloat(item.emi),
      total: parseFloat(item.total),
      settlement: false,
    });
    navigate("/outro");
  };
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      <div className="subscription-card" data-aos="flip-left">
        <div className="subscription-header">
          <h1>{item.months} months</h1>
        </div>
        {/*  */}
        <div className="subscription-body">
          <div className="subscription-flex">
            <p>Monthly EMI</p>
            <p>
              <span className="fw-bold">AED</span> {item.emi}
            </p>
          </div>
          <div className="subscription-flex total">
            <p>One Time Fee</p>
            <p>
              <span className="fw-bold">AED</span> 99
            </p>
          </div>
          <div className="subscription-flex">
            <p>Total</p>
            <p>
              <span className="fw-bold">AED</span> {item.total}
            </p>
          </div>
        </div>
        {/*  */}
        <div className="text-center">
          <button className="button" onClick={() => setModalVisible(true)}>
            Select plan
          </button>
        </div>
      </div>

      {/*  */}
      <div className={`modal-background ${modalVisible ? "active" : ""}`}>
        <div className="modal-container">
          <p className="text-center">
            Are your sure you want to select the {item.months} months
            restructuring plan?
          </p>
          <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
            <button
              className="red-button w-100"
              onClick={() => setModalVisible(false)}
            >
              No
            </button>
            <button className="button w-100" onClick={goToOutro}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionCard;
