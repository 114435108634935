import React, { useContext, useEffect, useState } from "react";
import { FaCreditCard, FaList, FaTrash, FaUser } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";
import creditorName from "../../assets/data/creditorName";
import debtType from "../../assets/data/debtType";
import axios from "axios";
import { AppContext } from "../../context/AppContext";

const Step1 = ({
  step1Inputs,
  handleStep1Inputs,
  summary,
  addCreditor,
  deleteCreditor,
  handleHarassmentChange,
}) => {
  const { url, getToken, user, setStep, preventChange, preventScroll } =
    useContext(AppContext);
  //
  const [modalVisible, setModalVisible] = useState(false);
  //
  const validateInputs = () => {
    for (let input of step1Inputs) {
      for (let key in input) {
        if (input[key] === "" || input[key] === null) {
          return false;
        }
      }
    }
    return true;
  };
  const [message, setMessage] = useState("");
  const proceed = () => {
    if (!validateInputs()) {
      setMessage("Enter complete details");
    } else {
      setMessage("");
      setModalVisible(true);
    }
  };
  const submit = async () => {
    try {
      const data = {
        creditor: step1Inputs,
        summary,
      };
      // //
      const token = await getToken();
      const zData = [
        {
          id: user.zohoID,
          Creditors_List: step1Inputs,
        },
      ];
      const zRes = await axios.put(
        `${url}/proxy?url=https://www.zohoapis.com/crm/v2/Loan_Restructuring`,
        zData,
        {
          headers: {
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (zRes.status === 200) {
        const summaryData = [
          {
            id: user.zohoID,
            Priority_Debts_Secured: summary.securedOutstanding,
            Priority_EMI: summary.priorityEMI,
            Unsecured_Debt: summary.unsecuredOutstanding,
            Unsecured_EMI: summary.unsecuredEMI,
          },
        ];
        const sRes = await axios.put(
          `${url}/proxy?url=https://www.zohoapis.com/crm/v2/Loan_Restructuring`,
          summaryData,
          {
            headers: {
              Authorization: `Zoho-oauthtoken ${token}`,
            },
          }
        );
        const res = await axios.put(`${url}/update/${user.mobile}`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.data.success === true) {
          setModalVisible(false);
          setStep(2);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  useEffect(() => {
    if (modalVisible) {
      setMessage("");
    }
  }, [modalVisible]);
  return (
    <>
      <h1>Tell us about your debts</h1>
      <div className="step1-container mt-4">
        {step1Inputs.map((item, index) => {
          return (
            <div
              className={`step1-card ${
                index === step1Inputs.length ? "" : "mb-2"
              }`}
              key={index}
            >
              <div className="d-flex align-items-center justify-content-between">
                <p className="fw-bold">Creditor {index + 1}</p>
                <div
                  className="delete-icon"
                  onClick={() => deleteCreditor(index)}
                >
                  <FaTrash />
                </div>
              </div>
              <div className="steps-input-grid">
                <div className="mt-2 mb-4">
                  <label className="label-flex">
                    <FaUser /> Creditor name
                  </label>
                  <select
                    className="input"
                    name="creditorName"
                    value={item.creditorName}
                    onChange={(e) => handleStep1Inputs(e, index)}
                  >
                    <option value=""></option>
                    {creditorName.map((item, index) => {
                      return (
                        <option value={item.name} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mt-2 mb-4">
                  <label className="label-flex">
                    <FaCreditCard />
                    Type of debt
                  </label>
                  <select
                    className="input"
                    name="debtType"
                    value={item.debtType}
                    onChange={(e) => handleStep1Inputs(e, index)}
                  >
                    <option value=""></option>
                    {debtType.map((item, index) => {
                      return (
                        <option value={item.label} key={index}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mt-2 mb-4">
                  <label className="label-flex">
                    <span className="fw-bold">AED</span>
                    Debt outstanding amount
                  </label>
                  <input
                    type="number"
                    className="input"
                    name="outstanding"
                    value={item.outstanding}
                    onChange={(e) => handleStep1Inputs(e, index)}
                    onKeyDown={preventChange}
                    onWheel={preventScroll}
                  />
                </div>
                <div className="mt-2 mb-4">
                  <label className="label-flex">
                    <span className="fw-bold">AED</span>
                    EMI amount
                  </label>
                  <input
                    type="number"
                    className="input"
                    name="emi"
                    value={item.emi}
                    onChange={(e) => handleStep1Inputs(e, index)}
                    onKeyDown={preventChange}
                    onWheel={preventScroll}
                  />
                </div>
                <div className="my-2">
                  <label className="label-flex">
                    <FaList />
                    Missed EMI
                  </label>
                  <input
                    type="number"
                    className="input"
                    name="missedEMI"
                    value={item.missedEMI}
                    onChange={(e) => handleStep1Inputs(e, index)}
                    onKeyDown={preventChange}
                    onWheel={preventScroll}
                  />
                </div>
                <div className="my-2">
                  <label>Are you struggling</label>
                  <div className="d-flex align-items-center justify-content-start gap-4">
                    <label className="label-flex">
                      <input
                        type="radio"
                        name={`harassed-${index}`}
                        checked={item.harassment === true}
                        onChange={() => handleHarassmentChange(index, true)}
                      />
                      Yes
                    </label>
                    <label className="label-flex">
                      <input
                        type="radio"
                        name={`harassed-${index}`}
                        checked={item.harassment === false}
                        onChange={() => handleHarassmentChange(index, false)}
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <p className="text-danger text-end">{message}</p>
      <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
        <button className="button" onClick={addCreditor}>
          Add creditor
        </button>
        <button className="button" onClick={proceed}>
          Proceed
        </button>
      </div>

      {/*  */}
      <div className={`modal-background ${modalVisible ? "active" : ""}`}>
        <div className="modal-container">
          <div className="step1-summary">
            <h2 className="mb-2">Debt Details Summary</h2>
            <div className="summary-flex">
              <p>Priority debts (Secured)</p>
              <p>AED {summary.securedOutstanding}</p>
            </div>
            <div className="summary-flex">
              <p>Unsecured debts</p>
              <p>AED {summary.unsecuredOutstanding}</p>
            </div>
            <div className="summary-flex mt-2">
              <p className="fw-bold">Total debts outstanding</p>
              <p
                className="primary-pill text-end"
                style={{ minWidth: "100px" }}
              >
                AED {summary.securedOutstanding + summary.unsecuredOutstanding}
              </p>
            </div>
          </div>
          <hr />
          <div className="step1-summary mt-2">
            <h2 className="mb-2">Monthly EMI</h2>
            <div className="summary-flex">
              <p>Priority EMI (Secured)</p>
              <p>AED {summary.priorityEMI}</p>
            </div>
            <div className="summary-flex">
              <p>Unsecured EMI</p>
              <p>AED {summary.unsecuredEMI}</p>
            </div>
            <div className="summary-flex mt-2">
              <p className="fw-bold">Total monthly EMI</p>
              <p
                className="primary-pill text-end"
                style={{ minWidth: "100px" }}
              >
                AED {summary.priorityEMI + summary.unsecuredEMI}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
            <button
              className="red-button w-100"
              onClick={() => setModalVisible(false)}
            >
              cancel
            </button>
            <button className="button w-100" onClick={submit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
