const creditorName = [
  { label: "Mashreq" },
  { label: "Emirates NBD" },
  { label: "Emirates Islamic Bank" },
  { label: "Dubai First Bank" },
  { label: "Abu Dhabi Islamic Bank (ADCB)" },
  { label: "Rakbank" },
  { label: "Citi Bank" },
  { label: "Dunia Finance" },
  { label: "Cashnow" },
  { label: "Tabby" },
  { label: "Al Hilal Bank" },
  { label: "Commercial Bank of Dubai (CBD)" },
  { label: "Aafaq Islamic Finance" },
  { label: "HSBC" },
  { label: "Standard Chartered" },
  { label: "DEEM Finance" },
  { label: "Abu Dhabi Islamic Bank (ADIB)" },
];

export default creditorName;
