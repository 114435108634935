import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import CircularBar from "../components/CircularBar";
import { FaEnvelope, FaPhoneAlt, FaRupeeSign } from "react-icons/fa";
import money from "../assets/images/home-icons/money.png";
import activatedPlan from "../assets/images/home-icons/activated-plan.png";
import totalEMI from "../assets/images/home-icons/total-emi.png";
import emi from "../assets/images/home-icons/emi.png";
import dispossibleIncome from "../assets/images/home-icons/dispossible-income.png";
import laptopBoi from "../assets/images/laptop-boi.png";

const Home = () => {
  const { user } = useContext(AppContext);
  //
  return (
    <div className="container p-2">
      {user !== null && (
        <>
          <div className="home-grid">
            <div className="div1">
              <div className="blue-left">
                <h1>
                  Welcome back <br />
                  {user.name}!
                </h1>
                <div className="">
                  <p className="label-flex">
                    <FaEnvelope />
                    Email address
                  </p>
                  <p>{user.email}</p>
                </div>
                <div>
                  <p>
                    {" "}
                    <FaPhoneAlt /> Mobile number
                  </p>
                  <p>{user.mobile}</p>
                </div>
              </div>
              <div className="blue-right">
                <img src={laptopBoi} alt="" />
              </div>
            </div>
            <div className="div2">
              <div className="home-image">
                <img src={money} alt="" />
              </div>
              <small>Total Outstanding</small>
              <h2 className="rupee-flex">
                <span className="fw-bold">AED</span>
                12345
              </h2>
            </div>
            <div className="div3">
              <div className="home-image">
                <img src={totalEMI} alt="" />
              </div>
              <small>Total EMI</small>
              <h2 className="rupee-flex">
                <span className="fw-bold">AED</span>
                12345
              </h2>
            </div>
            <div className="div4">
              <div className="home-image">
                <img src={activatedPlan} alt="" />
              </div>
              <small>Activated Plan</small>
              <h2>18 months</h2>
            </div>
            <div className="div5">
              <div className="home-image">
                <img src={emi} alt="" />
              </div>
              <small>EMI</small>
              <h2 className="rupee-flex">
                <span className="fw-bold">AED</span>
                12345
              </h2>
            </div>
            <div className="div6">
              <div className="home-image">
                <img src={dispossibleIncome} alt="" />
              </div>
              <small>Dispossible income</small>
              <h2 className="rupee-flex">
                <span className="fw-bold">AED</span>
                12345
              </h2>
            </div>
            <div className="div7">
              <div>
                <small>Dispossible Income %</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  12345
                </h2>
              </div>
              <div>
                <CircularBar percent={63} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
