import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { FaRupeeSign } from "react-icons/fa";

const Creditors = () => {
  const { user } = useContext(AppContext);
  return (
    <>
      {user !== null && (
        <div className="container p-2">
          {user.creditor.length !== 0 ? (
            <>
              <div className="creditor-container">
                <table className="table table-bordered table-hover creditor-table">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Creditor name</th>
                      <th>Debt type</th>
                      <th>Outstanding</th>
                      <th>EMI</th>
                      <th>Missed EMI</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.creditor.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.creditorName}</td>
                          <td>{item.debtType}</td>
                          <td>
                            <span className="fw-bold">AED</span>
                            {item.outstanding.toLocaleString("en-IN")}
                          </td>
                          <td>
                            <span className="fw-bold">AED</span>
                            {item.emi.toLocaleString("en-IN")}
                          </td>
                          <td>{item.missedEMI}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>{" "}
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default Creditors;
