import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import SignInLeft from "../components/SignInLeft";
import { FaEnvelope, FaKey, FaPhoneAlt, FaUser } from "react-icons/fa";

const Signup = () => {
  const { url, getToken } = useContext(AppContext);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  //
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const signup = async () => {
    const { name, email, mobile, password } = inputs;
    if (!name || !email || !mobile || !password) {
      setError(true);
      setMessage("Enter your complete details");
    } else {
      const data = {
        name,
        email,
        mobile,
        zohoID: "",
        password,
      };
      try {
        // //
        const token = await getToken();
        const zData = [
          {
            Name: name,
            Email: email,
            Phone_Number: mobile,
            Password: password,
            Address_Line: "Test",
            Emirate: "TEST",
            Post_Code: "123SDFG",
          },
        ];
        const zRes = await axios.post(
          `${url}/proxy?url=https://www.zohoapis.com/crm/v2/Loan_Restructuring`,
          zData,
          {
            headers: {
              Authorization: `Zoho-oauthtoken ${token}`,
            },
          }
        );
        if (zRes.status === 201) {
          data.zohoID = zRes.data.data[0].details.id;
          const res = await axios.post(`${url}/user/signup`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setError(false);
          setMessage("Account created. Pleaselogin to continue.");
          localStorage.setItem(
            "smlPlatFromUserToken",
            JSON.stringify(res.data.result.mobile)
          );
          setTimeout(() => {
            navigate("/login", { replace: true });
          }, 5000);
        } else if (zRes.data.data[0].code === "INVALID_DATA") {
          setError(true);
          setMessage("Invalid data");
        } else if (zRes.status === 202) {
          setError(true);
          setMessage("Account already exist.");
        } else if (zRes.status === 204) {
          setError(true);
          setMessage("Something went wrong");
        } else if (zRes.status === 401) {
          setError(true);
          setMessage("Token expired. Please retry after some time");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <div className="container-fluid p-0" style={{ overflow: "hidden" }}>
        <div className="signin-grid">
          <SignInLeft />
          {/*  */}
          <div data-aos="fade-left">
            <div className="mb-4">
              <h2 className="mb-2">
                Welcome to Taurus Credit Management Platform
              </h2>
              <p>
                Restructure or settle your credit cards and loans to what you
                can afford.
              </p>
            </div>
            <div>
              <div className="form-div mb-4">
                <label className="label-flex">
                  <FaUser />
                  Full name
                </label>
                <input
                  type="text"
                  className="input"
                  name="name"
                  value={inputs.name}
                  onChange={handleInputs}
                  autoComplete="off"
                />
              </div>
              <div className="form-div mb-4">
                <label className="label-flex">
                  <FaPhoneAlt />
                  Mobile number
                </label>
                <input
                  type="number"
                  className="input"
                  name="mobile"
                  value={inputs.mobile}
                  onChange={handleInputs}
                  autoComplete="off"
                />
              </div>
              <div className="form-div mb-4">
                <label className="label-flex">
                  <FaEnvelope /> Email address
                </label>
                <input
                  type="email"
                  className="input"
                  name="email"
                  value={inputs.email}
                  onChange={handleInputs}
                  autoComplete="off"
                />
              </div>
              <div className="form-div mb-4">
                <label className="label-flex">
                  <FaKey /> Password
                </label>
                <input
                  type="password"
                  className="input"
                  name="password"
                  value={inputs.password}
                  onChange={handleInputs}
                  autoComplete="off"
                />
              </div>

              <div className="text-end">
                <p className={error ? "text-danger" : "text-success"}>
                  {message}
                </p>
                <button className="button" onClick={signup}>
                  Signup
                </button>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center gap-2">
              <p>Already have an account?</p>
              <Link to="/login">Login</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
