import React, { useContext, useMemo, useState } from "react";
import SubscriptionCard from "./SubscriptionCard";
import happy from "../../assets/images/customer-service.png";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";

const Step3 = ({ summary }) => {
  const { setPlan } = useContext(AppContext);
  const navigate = useNavigate();
  const [cardDetails, setCardDetails] = useState([
    {
      emi: 0,
      months: 24,
      total: 0,
    },
    {
      emi: 0,
      months: 36,
      total: 0,
    },
    {
      emi: 0,
      months: 48,
      total: 0,
    },
  ]);
  const [settlement, setSettlement] = useState({
    first: 0,
    second: 0,
  });
  useMemo(() => {
    const updatedCardDetails = cardDetails.map((item) => {
      const emi = (
        parseFloat(summary.unsecuredOutstanding) / parseFloat(item.months)
      ).toFixed(2);
      const total = (parseFloat(emi) + 99).toFixed(0);
      return {
        ...item,
        emi,
        total,
      };
    });
    setCardDetails(updatedCardDetails);
    //
    const first = (parseFloat(summary.unsecuredOutstanding) * 40) / 100;
    const second = (parseFloat(summary.unsecuredOutstanding) * 50) / 100;
    setSettlement({
      first,
      second,
    });
  }, []);
  //
  const goToOutro = () => {
    setPlan({
      months: 0,
      emi: 0,
      total: 0,
      settlement: true,
    });
    navigate("/outro");
  };
  return (
    <>
      <div>
        <h1>Congratulations! You qualify for a restructuring.</h1>
        <div className="congrats-flex">
          <div>
            <img src={happy} alt="" />
          </div>
          <div>
            <p className="text-white">
              We will negotiate with all your creditors to restructure/settle
              your credit cards and loans. This will involve your current EMI to
              be reduced to a level where you can afford to service these credit
              cards and loans and help you regain control and achieve financial
              stability.
            </p>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h2>Choose your restructuring plan</h2>
        <div className="subscription-container">
          {cardDetails.map((item, index) => {
            return <SubscriptionCard key={index} item={item} />;
          })}
          <div className="subscription-card" data-aos="flip-left">
            <div className="subscription-header">
              <h1>Settlement</h1>
            </div>
            {/*  */}
            <div className="subscription-body text-center">
              <div className="total">
                <p>40% to 50% on Unsecured Outstanding</p>
              </div>
              <div>
                AED {settlement.first} to AED {settlement.second}
              </div>
            </div>
            {/*  */}
            <div className="text-center">
              <button className="button" onClick={goToOutro}>
                Select plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3;
