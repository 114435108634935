const congratspoints = [
  {
    point:
      "Say goodbye to debt stress! Join thousands of customers who have achieved financial freedom",
  },
  {
    point:
      "Enroll now for only AED 99 to restructure/settle all your credit cards and loans",
  },
  {
    point:
      "Legal cover to ensure that the restructuring/settlement documentation are as negotiated",
  },
  {
    point: "Client dashboard",
  },
  {
    point: "Dedicated relationship manager ",
  },
];

export default congratspoints;
