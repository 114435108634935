import React from "react";

const Footer = () => {
  return (
    <footer className="container-fluid p-0">
      <div className="container p-2 text-center">
        <p className="text-capitalize">
          &copy; copyright 2024 all rights reserved | settlemyloan
        </p>
      </div>
    </footer>
  );
};

export default Footer;
