import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import Lottie from "react-lottie";
import animationData from "../assets/data/Animation - 1721975445804.json";
import { useNavigate, useParams } from "react-router-dom";

const ConfirmPayment = () => {
  const { url, user, plan } = useContext(AppContext);
  const navigate = useNavigate();
  const { merchantTransactionId } = useParams();
  const [paymentSummary, setPaymentSummary] = useState(null);
  const confirmPayment = async () => {
    if (!user.payment) {
      navigate("/steps", { replace: true });
    }
    try {
      const data = {
        mobile: user.mobile,
        months: plan.months,
        total: plan.total,
      };
      const res = await axios.post(
        `${url}/confirmpayment/${merchantTransactionId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setPaymentSummary(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (user !== null) {
      confirmPayment();
    }
  }, [user]);
  //
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  //
  const [counter, setCounter] = useState(5);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (counter <= 0) {
      navigate("/", { replace: true });
    }
  }, [counter]);
  return (
    <>
      <div className="container p-2">
        {paymentSummary !== null ? (
          <>
            <div className="successful-payment-grid">
              <div>
                <h2>Payment done successfully!</h2>
                <p>Amount: AED{parseFloat(paymentSummary.data.amount / 100)}</p>
                <p>Transaction ID: {paymentSummary.data.transactionId}</p>
                <p>Mode: {paymentSummary.data.paymentInstrument.type}</p>
              </div>
              <div className="lottie-box">
                <Lottie options={defaultOptions} />
              </div>
              <p>Redirecing to Home page in {counter}s</p>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ConfirmPayment;
