import React from "react";
import logo from "../assets/images/logo.png";

const SignInLeft = () => {
  return (
    <>
      <div className="signin-left">
        <div className="big-logo">
          <img src={logo} alt="" />
        </div>
        <h2>Loan Restructuring with TCM</h2>

        <ul className="list-unstyled">
          <li>Do you have credit cards or loans?</li>
          <li className="my-2">
            Find out if you qualify for restructuring with affordable EMIs.
          </li>
          <li>
            This won't impact your credit score, in line with The Centre Bank's
            policy.
          </li>
        </ul>

        <p className="text-capitalize d-md-block d-none">
          &copy; copyright 2024 all rights reserved | Taurus Credit Management
        </p>
      </div>
    </>
  );
};

export default SignInLeft;
