import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { FaEnvelope, FaPhoneAlt, FaTrash, FaUser } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("smlPlatFromUserToken");
    navigate("/login", { replace: true });
  };
  return (
    <>
      {user !== null ? (
        <div className="container-fluid p-2">
          <div className="container">
            <div className="profile-grid">
              <div>
                <FaUser />
                <small>Full name</small>
                <h2>{user.name}</h2>
              </div>
              <div>
                <FaEnvelope />
                <small>Email addres</small>
                <h2 className="text-lowercase">{user.email}</h2>
              </div>
              <div>
                <FaPhoneAlt />
                <small>Mobile number</small>
                <h2>{user.mobile}</h2>
              </div>
            </div>
            <button className="red-button icon-button mt-2" onClick={logout}>
              logout <IoMdLogOut />
            </button>
            <hr />
            <div>
              <p>Want to delete your account data?</p>
              <a
                href="https://platform-delete.tauruscreditmanagement.ae"
                target="_blank"
                className="red-button icon-button mt-2 d-inline-block"
              >
                Delete <FaTrash />
              </a>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Profile;
