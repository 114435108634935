import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import logo from "../assets/images/logo.png";
import {
  FaBars,
  FaEnvelope,
  FaPhoneAlt,
  FaTimes,
  FaUser,
} from "react-icons/fa";

const Navbar = () => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("smlPlatFromUserToken");
    navigate("/login", { replace: true });
  };
  //
  const [navToggle, setNavToggle] = useState(false);
  //
  const navbar = useRef(null);
  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > 100) {
        navbar.current.classList.add("shadow");
      } else if (window.scrollY < 100) {
        navbar.current.classList.remove("shadow");
      }
    };
  }, []);
  return (
    <>
      <nav className="container-fluid p-2" ref={navbar}>
        {user !== null && (
          <div className="container">
            {user.payment === true ? (
              <>
                <div className={`nav-flex ${navToggle ? "active" : ""}`}>
                  <div className="d-flex align-items-center justify-content-between">
                    <Link to="/" className="logo">
                      <img src={logo} alt="" />
                    </Link>
                    <div
                      className="menu"
                      onClick={() => setNavToggle(!navToggle)}
                    >
                      {navToggle ? <FaTimes /> : <FaBars />}
                    </div>
                  </div>
                  {/*  */}
                  <ul className="navlinks">
                    <li>
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to="/finances">Finances</NavLink>
                    </li>
                    <li>
                      <NavLink to="/creditors">Creditors</NavLink>
                    </li>
                    <li>
                      <NavLink to="/profile">Profile</NavLink>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <div className="container">
                <div className="d-flex align-items-center justify-content-between">
                  <Link to="/" className="logo">
                    <img src={logo} alt="" />
                  </Link>

                  <ul className="list-unstyled">
                    <li
                      className="profile-link"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Profile
                      <div className="dropdown">
                        <ul className="dropdown-menu p-2">
                          <li className="text-capitalize label-flex">
                            <FaUser />
                            {user.name}
                          </li>
                          <li className=" label-flex">
                            <FaEnvelope />
                            {user.email}
                          </li>
                          <li className=" label-flex">
                            <FaPhoneAlt />
                            {user.mobile}
                          </li>
                          <hr />
                          <li>
                            <button
                              className="red-button w-100"
                              onClick={logout}
                            >
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
