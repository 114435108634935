const debtType = [
  { label: "Credit Card", type: "unsecured" },
  { label: "Personal Loan", type: "unsecured" },
  { label: "Overdraft", type: "secured" },
  { label: "Business Loan", type: "secured" },
  { label: "Education Loan", type: "unsecured" },
  { label: "Store Card", type: "unsecured" },
  { label: "Pay Day Loan", type: "unsecured" },
  { label: "Home Loan", type: "secured" },
  { label: "Car Loan", type: "secured" },
  { label: "Gold Loan", type: "secured" },
  { label: "Term Loan", type: "unsecured" },
  { label: "Business Loan Unsecured", type: "unsecured" },
  { label: "Business Loan Secured", type: "secured" },
  { label: "LAP", type: "secured" },
  { label: "Secured Overdraft", type: "secured" },
  { label: "Unsecured Overdraft", type: "unsecured" },
];

export default debtType;
