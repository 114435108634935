import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import step1 from "../assets/images/welcome-icons/step1.png";
import step2 from "../assets/images/welcome-icons/step2.png";
import step3 from "../assets/images/welcome-icons/step3.png";

const WelcomePage = () => {
  const { user, setStep } = useContext(AppContext);
  const navigate = useNavigate();
  const goToStep1 = () => {
    setStep(1);
    navigate("/steps", { replace: true });
  };
  return (
    <>
      <div className="welcome-container">
        <div>
          <p>
            Hello,{" "}
            <span className="fw-bold text-capitalize">{user && user.name}</span>
          </p>
          <p className="fw-bold">Check your eligibility</p>
          <p>
            Take the following 3 steps towards a stress-free life. Find out if
            you qualify for restructuring without it affecting your credit
            score.
          </p>
        </div>

        <h1 data-aos="zoom-in">Let's get started with three simple steps</h1>

        <div>
          <div className="welcome-box">
            <div className="welcome-grid">
              <div className="welcome-image">
                <img src={step1} alt="" />
              </div>
              <div>
                <h2>Step One - Share Your Debts Details</h2>
              </div>
            </div>
            <p>
              Provide us with information about your current debts. This helps
              us understand your situation and tailor the best restructuring
              that fits your needs.
            </p>
          </div>
          <div className="welcome-box my-4">
            <div className="welcome-grid">
              <div className="welcome-image">
                <img src={step2} alt="" />
              </div>
              <div>
                <h2>Step Two - Outline your Income and expenses </h2>
              </div>
            </div>
            <p>
              Tell us about your income and monthly expenditures. This will
              allow us to assess your financial landscape and identify the best
              options for you.
            </p>
          </div>
          <div className="welcome-box">
            <div className="welcome-grid">
              <div className="welcome-image">
                <img src={step3} alt="" />
              </div>
              <div>
                <h2>Step Three - Explore your restructuring plans</h2>
              </div>
            </div>
            <p>
              Based on the information you provide, we will present personalized
              restructuring plans designed to help you regain control and
              achieve financial stability.
            </p>
          </div>
        </div>

        <button className="button" onClick={goToStep1}>
          Proceed
        </button>
      </div>
    </>
  );
};

export default WelcomePage;
