import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Footer from "../components/Footer";
import SignInLeft from "../components/SignInLeft";
import { FaKey, FaPhoneAlt, FaUser } from "react-icons/fa";

const Login = () => {
  const { url } = useContext(AppContext);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    mobile: "",
    password: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const login = async () => {
    if (!inputs.mobile || !inputs.password) {
      setError(true);
      setMessage("Enter complete details");
    } else {
      try {
        const data = {
          mobile: inputs.mobile,
          password: inputs.password,
        };
        const res = await axios.post(`${url}/user/login`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(res.data);
        if (res.data.success === false) {
          setError(true);
          setMessage(res.data.message);
        } else if (res.data.success === true) {
          setError(false);
          setMessage("Login successful");
          localStorage.setItem(
            "smlPlatFromUserToken",
            JSON.stringify(res.data.token)
          );
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <div className="container-fluid p-0" style={{ overflow: "hidden" }}>
        <div className="signin-grid">
          <SignInLeft />
          {/*  */}
          <div data-aos="fade-left">
            <div className="mb-4">
              <h2>Welcome back</h2>
              <p>Please login to continue</p>
            </div>
            <div>
              <div className="form-div mb-4">
                <label className="label-flex">
                  <FaPhoneAlt />
                  Mobile number
                </label>
                <input
                  type="number"
                  className="input"
                  name="mobile"
                  value={inputs.mobile}
                  onChange={handleInputs}
                  autoComplete="off"
                />
              </div>
              <div className="form-div mb-4">
                <label className="label-flex">
                  <FaKey />
                  Password
                </label>
                <input
                  type="password"
                  className="input"
                  name="password"
                  value={inputs.password}
                  onChange={handleInputs}
                  autoComplete="off"
                />
              </div>

              <div className="text-end">
                <p className={error ? "text-danger" : "text-success"}>
                  {message}
                </p>
                <button className="button" onClick={login}>
                  login
                </button>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center gap-2 mt-4">
              <p>Don't have an account?</p>
              <Link to="/signup">Signup</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="d-md-none">
        <Footer />
      </div>
    </>
  );
};

export default Login;
