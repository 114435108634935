import React, { useContext, useEffect, useState } from "react";
import Step1 from "../components/step1/Step1";
import Step2 from "../components/step2/Step2";
import Step3 from "../components/step3/Step3";
import { AppContext } from "../context/AppContext";
import debtType from "../assets/data/debtType";

const Steps = () => {
  const { step, setStep, user } = useContext(AppContext);
  const steps = ["step 1", "step 2", "step 3"];
  const changeStep = (index) => {
    if (index === 1) {
      setStep(index);
    }
    if (index === 2 && user.creditor.length !== 0) {
      setStep(index);
    } else {
      return;
    }
    if (index === 3) {
      return;
    }
  };
  // step 1
  const inputTemplate = {
    creditorName: "",
    debtType: "",
    outstanding: "",
    emi: "",
    missedEMI: "",
    harassment: false,
  };
  const [step1Inputs, setStep1Inputs] = useState([inputTemplate]);
  const handleStep1Inputs = (e, index) => {
    const updatedInputs = step1Inputs.map((item, i) => {
      if (index === i) {
        return { ...item, [e.target.name]: e.target.value };
      }
      return item;
    });
    setStep1Inputs(updatedInputs);
  };
  //
  const addCreditor = () => {
    setStep1Inputs([...step1Inputs, inputTemplate]);
  };
  const deleteCreditor = (id) => {
    const filtered = step1Inputs.filter((_, index) => {
      return index !== id;
    });
    setStep1Inputs(filtered);
  };
  const handleHarassmentChange = (index, value) => {
    const updatedInputs = [...step1Inputs];
    updatedInputs[index].harassment = value;
    setStep1Inputs(updatedInputs);
  };
  //
  useEffect(() => {
    if (user !== null) {
      if (user.creditor.length > 0) {
        setStep1Inputs(user.creditor);
      }
      if (user.salary) {
        setStep2Inputs({
          salary: user.salary,
          businessIncome: user.businessIncome,
          familySupport: user.familySupport,
          otherIncome: user.otherIncome,
          salaryDate: user.salaryDate,
          rent: user.rent,
          food: user.food,
          electricity: user.electricity,
          gas: user.gas,
          other: user.other,
          travel: user.travel,
          subscription: user.subscription,
          dining: user.dining,
          houseHelp: user.houseHelp,
          outing: user.outing,
        });
      }
    }
  }, [user]);
  //
  const [summary, setSummary] = useState({
    securedOutstanding: 0,
    unsecuredOutstanding: 0,
    priorityEMI: 0,
    unsecuredEMI: 0,
  });
  useEffect(() => {
    const newSummary = step1Inputs.reduce(
      (acc, item) => {
        const debtTypeInfo = debtType.find((dt) => dt.label === item.debtType);
        if (debtTypeInfo) {
          if (debtTypeInfo.type === "secured") {
            acc.securedOutstanding += parseFloat(item.outstanding || 0);
            acc.priorityEMI += parseFloat(item.emi || 0);
          } else {
            acc.unsecuredOutstanding += parseFloat(item.outstanding || 0);
            acc.unsecuredEMI += parseFloat(item.emi || 0);
          }
        }
        return acc;
      },
      {
        securedOutstanding: 0,
        unsecuredOutstanding: 0,
        priorityEMI: 0,
        unsecuredEMI: 0,
      }
    );
    setSummary(newSummary);
  }, [step1Inputs]);
  //
  // step 2
  const [step2Inputs, setStep2Inputs] = useState({
    salary: "",
    businessIncome: "",
    familySupport: "",
    otherIncome: "",
    salaryDate: "",
    rent: "",
    food: "",
    electricity: "",
    gas: "",
    other: "",
    travel: "",
    subscription: "",
    dining: "",
    houseHelp: "",
    outing: "",
  });
  //
  return (
    <div className="container p-2">
      <div className="steps-counter-container mb-4">
        {steps.map((item, index) => (
          <div
            key={index}
            className={`step-button ${step > index ? "active" : ""}`}
            onClick={() => changeStep(index + 1)}
          >
            {item}
          </div>
        ))}
      </div>

      {step === 1 && (
        <Step1
          step1Inputs={step1Inputs}
          handleStep1Inputs={handleStep1Inputs}
          summary={summary}
          addCreditor={addCreditor}
          deleteCreditor={deleteCreditor}
          handleHarassmentChange={handleHarassmentChange}
        />
      )}
      {step === 2 && (
        <Step2
          step1Inputs={step1Inputs}
          step2Inputs={step2Inputs}
          setStep2Inputs={setStep2Inputs}
          summary={summary}
        />
      )}
      {step === 3 && <Step3 summary={summary} />}
    </div>
  );
};

export default Steps;
