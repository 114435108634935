import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { FaRupeeSign } from "react-icons/fa";
//
import salary from "../assets/images/income-icons/salary.png";
import businessIncome from "../assets/images/income-icons/business-income.png";
import familySupport from "../assets/images/income-icons/family-support.png";
import otherIncome from "../assets/images/income-icons/other-income.png";
import salaryDate from "../assets/images/income-icons/salary-date.png";
//
import rent from "../assets/images/living-icons/rent.png";
import food from "../assets/images/living-icons/food.png";
import electricity from "../assets/images/living-icons/electricity.png";
import gas from "../assets/images/living-icons/gas.png";
import others from "../assets/images/living-icons/others.png";
//
import travel from "../assets/images/lifestyle-icons/travel.png";
import digitalSubscription from "../assets/images/lifestyle-icons/digital-subscription.png";
import diningOIt from "../assets/images/lifestyle-icons/dining-out.png";
import houseHelp from "../assets/images/lifestyle-icons/house-help.png";
import outing from "../assets/images/lifestyle-icons/outing.png";
const Finances = () => {
  const { user } = useContext(AppContext);
  return (
    <>
      {user !== null && (
        <div className="container p-2">
          <div className="mb-4">
            <h2 className="mb-2">Income source</h2>
            <div className="finance-grid">
              <div>
                <div className="finance-image">
                  <img src={salary} alt="" />
                </div>
                <small>Salary</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.salary.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={businessIncome} alt="" />
                </div>
                <small>Business Income</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.businessIncome.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={familySupport} alt="" />
                </div>
                <small>Family support</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.familySupport.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={otherIncome} alt="" />
                </div>
                <small>Other income</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.otherIncome.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={salaryDate} alt="" />
                </div>
                <small>Salary date</small>
                <h2>{user.salaryDate.split("-").reverse().join("-")}</h2>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h2 className="mb-2">Living expense</h2>
            <div className="finance-grid">
              <div>
                <div className="finance-image">
                  <img src={rent} alt="" />
                </div>
                <small>Rent</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.rent.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={food} alt="" />
                </div>
                <small>Food</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.food.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={electricity} alt="" />
                </div>
                <small>Electricity</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.electricity.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={gas} alt="" />
                </div>
                <small>Gas</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.gas.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={others} alt="" />
                </div>
                <small>Other</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.other.toLocaleString("en-IN")}
                </h2>
              </div>
            </div>
          </div>
          <div>
            <h2 className="mb-2">Lifestyle expense</h2>
            <div className="finance-grid">
              <div>
                <div className="finance-image">
                  <img src={travel} alt="" />
                </div>
                <small>Travel</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.travel.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={digitalSubscription} alt="" />
                </div>
                <small>Digital subscription</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.subscription.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={diningOIt} alt="" />
                </div>
                <small>Dining out</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.dining.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={houseHelp} alt="" />
                </div>
                <small>House help</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.houseHelp.toLocaleString("en-IN")}
                </h2>
              </div>
              <div>
                <div className="finance-image">
                  <img src={outing} alt="" />
                </div>
                <small>Outing</small>
                <h2 className="rupee-flex">
                  <span className="fw-bold">AED</span>
                  {user.outing.toLocaleString("en-IN")}
                </h2>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Finances;
