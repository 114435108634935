import React, { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate, useLocation } from "react-router-dom";

const Auth = ({ Component }) => {
  const { getUser, step, user } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const checkUser = async () => {
      await getUser();

      if (user !== null) {
        if (
          user.payment === false &&
          ["/", "/finances", "/creditors", "/profile"].includes(
            location.pathname
          )
        ) {
          navigate("/welcome", { replace: true });
        }
      }
    };

    checkUser();
  }, [step, location.pathname]);
  //
  return (
    <>
      <Navbar />
      <Component />
      <Footer />
    </>
  );
};

export default Auth;
