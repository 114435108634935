import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularBar = ({ percent }) => {
  return (
    <div style={{ width: "75px", height: "75px" }}>
      <CircularProgressbar
        value={percent}
        text={`${percent}%`}
        styles={buildStyles({
          textColor: "#000",
          pathColor: "#2877ff",
          trailColor: "#75d526",
        })}
      />
    </div>
  );
};

export default CircularBar;
