import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Steps from "./pages/Steps";
import Auth from "./utility/Auth";
import ConfirmPayment from "./pages/ConfirmPayment";
import AOS from "aos";
import "aos/dist/aos.css";
import Outro from "./pages/Outro";
import WelcomePage from "./pages/WelcomePage";
import Finances from "./pages/Finances";
import Creditors from "./pages/Creditors";
import Profile from "./pages/Profile";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<Auth Component={Home} />} />
        <Route path="/finances" element={<Auth Component={Finances} />} />
        <Route path="/creditors" element={<Auth Component={Creditors} />} />
        <Route path="/profile" element={<Auth Component={Profile} />} />

        <Route path="/welcome" element={<Auth Component={WelcomePage} />} />
        <Route path="/steps" element={<Auth Component={Steps} />} />
        <Route path="/outro" element={<Auth Component={Outro} />} />

        <Route
          path="/confirmpayment/:merchantTransactionId"
          element={<Auth Component={ConfirmPayment} />}
        />

        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
};

export default App;
