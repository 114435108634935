import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../context/AppContext";

const Step2 = ({ setStep2Inputs, step2Inputs, summary }) => {
  const { url, user, getToken, setStep, preventChange, preventScroll } =
    useContext(AppContext);
  const parseInput = (value) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };
  //
  const [message, setMessage] = useState("");
  const update = async () => {
    try {
      // //
      const token = await getToken();
      const zData = [
        {
          id: user.zohoID,
          Other_Income: step2Inputs.otherIncome,
          Salary: step2Inputs.salary,
          Salary_Date: step2Inputs.salaryDat,
          Business_Income: step2Inputs.businessIncome,
          Family_Support: step2Inputs.familySupport,
          "Rent/Maintenance": step2Inputs.rent,
          Electricity_Bill: step2Inputs.electricity,
          Gas_Bill: step2Inputs.gas,
          Phone_Bill: "5000",
          Food_Expenses: step2Inputs.food,
          Other_Utilities: step2Inputs.other,
          "Travel_/Fuel": step2Inputs.travel,
          Digital_Subscription: step2Inputs.subscription,
          Dining_Out: step2Inputs.dining,
          House_Help: step2Inputs.houseHelp,
          Outing: step2Inputs.outing,
        },
      ];
      const zRes = await axios.put(
        `${url}/proxy?url=https://www.zohoapis.com/crm/v2/Loan_Restructuring`,
        zData,
        {
          headers: {
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (zRes.status === 200) {
        const data = {
          salary: parseFloat(step2Inputs.salary),
          businessIncome: parseFloat(step2Inputs.businessIncome),
          familySupport: parseFloat(step2Inputs.familySupport),
          otherIncome: parseFloat(step2Inputs.otherIncome),
          salaryDate: step2Inputs.salaryDate,
          rent: parseFloat(step2Inputs.rent),
          food: parseFloat(step2Inputs.food),
          electricity: parseFloat(step2Inputs.electricity),
          gas: parseFloat(step2Inputs.gas),
          other: parseFloat(step2Inputs.other),
          travel: parseFloat(step2Inputs.travel),
          subscription: parseFloat(step2Inputs.subscription),
          dining: parseFloat(step2Inputs.dining),
          houseHelp: parseFloat(step2Inputs.houseHelp),
          outing: parseFloat(step2Inputs.outing),
        };
        const res = await axios.put(`${url}/update/${user.mobile}`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.data.success === true) {
          setStep(3);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const [modalVisible, setModalVisible] = useState(false);
  const [errors, setErrors] = useState({
    salary: "",
    otherIncome: "",
    salaryDate: "",
    rent: "",
    food: "",
    electricity: "",
    gas: "",
    travel: "",
  });
  const handleStep2Inputs = (e) => {
    const { name, value } = e.target;
    setStep2Inputs({
      ...step2Inputs,
      [name]: value,
    });

    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };
  const showModal = () => {
    let hasErrors = false;
    let newErrors = { ...errors };

    if (!step2Inputs.salary) {
      newErrors.salary = "Salary is required";
      hasErrors = true;
    }

    if (!step2Inputs.otherIncome) {
      newErrors.otherIncome = "Other income is required";
      hasErrors = true;
    }

    if (!step2Inputs.salaryDate) {
      newErrors.salaryDate = "Salary  is required";
      hasErrors = true;
    }

    if (!step2Inputs.rent) {
      newErrors.rent = "Rent is required";
      hasErrors = true;
    }

    if (!step2Inputs.food) {
      newErrors.food = "Food is required";
      hasErrors = true;
    }

    if (!step2Inputs.electricity) {
      newErrors.electricity = "Electricity is required";
      hasErrors = true;
    }

    if (!step2Inputs.gas) {
      newErrors.gas = "Gas is required";
      hasErrors = true;
    }

    if (!step2Inputs.travel) {
      newErrors.travel = "Travel is required";
      hasErrors = true;
    }

    const areAllFieldsFilled = Object.values(step2Inputs).every(
      (input) => input !== ""
    );
    if (!areAllFieldsFilled) {
      setMessage("Enter 0 for empty fields");
      hasErrors = true;
    }

    setErrors(newErrors);

    if (!hasErrors) {
      setModalVisible(true);
    }
  };

  //
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, []);
  //
  const [totalIncome, setTotalIncome] = useState(0);
  const [dispossibleIncome, setDispossibleIncome] = useState(0);
  const [dipercent, setdipercent] = useState(0);
  useMemo(() => {
    const totalSalary =
      parseFloat(step2Inputs.salary) +
      parseFloat(step2Inputs.businessIncome) +
      parseFloat(step2Inputs.familySupport) +
      parseFloat(step2Inputs.otherIncome);
    setTotalIncome(totalSalary);
    //
    const totalDI =
      totalSalary -
      summary.priorityEMI -
      (parseFloat(step2Inputs.rent) +
        parseFloat(step2Inputs.food) +
        parseFloat(step2Inputs.electricity) +
        parseFloat(step2Inputs.gas) +
        parseFloat(step2Inputs.other) +
        parseFloat(step2Inputs.travel) +
        parseFloat(step2Inputs.subscription) +
        parseFloat(step2Inputs.dining) +
        parseFloat(step2Inputs.houseHelp) +
        parseFloat(step2Inputs.outing));
    setDispossibleIncome(totalDI);
    //
    const percentage = totalDI / totalSalary;
    setdipercent((percentage * 100).toFixed(2));
  }, [step2Inputs]);
  return (
    <>
      <div className="step2-bg">
        <h1>Income source</h1>
        <div className="steps-input-grid">
          <div className="mt-2 mb-4">
            <label>
              Salary <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="input"
              name="salary"
              value={step2Inputs.salary}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
            {errors.salary && <p className="text-danger">{errors.salary}</p>}
          </div>
          <div className="mt-2 mb-4">
            <label>Business income</label>
            <input
              type="number"
              className="input"
              name="businessIncome"
              value={step2Inputs.businessIncome}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
          </div>
          <div className="mt-2 mb-4">
            <label>family support</label>
            <input
              type="number"
              className="input"
              name="familySupport"
              value={step2Inputs.familySupport}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
          </div>
          <div className="mt-2 mb-4">
            <label>
              Other income <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="input"
              name="otherIncome"
              value={step2Inputs.otherIncome}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
            {errors.otherIncome && (
              <p className="text-danger">{errors.otherIncome}</p>
            )}
          </div>
          <div className="mt-2 mb-4">
            <label>
              Salary date <span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="input"
              name="salaryDate"
              value={step2Inputs.salaryDate}
              onChange={handleStep2Inputs}
            />
            {errors.salaryDate && (
              <p className="text-danger">{errors.salaryDate}</p>
            )}
          </div>
        </div>
        <div className="income-summary">
          <p>Total monthly income</p>
          <p>
            AED{" "}
            {parseInput(step2Inputs.salary) +
              parseInput(step2Inputs.businessIncome) +
              parseInput(step2Inputs.familySupport) +
              parseInput(step2Inputs.otherIncome)}
          </p>
        </div>
      </div>
      {/*  */}
      <div className="step2-bg mt-2">
        <h1>Living expence</h1>
        <div className="steps-input-grid">
          <div className="mt-2 mb-4">
            <label>
              Rent/Maintenance <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="input"
              name="rent"
              value={step2Inputs.rent}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
            {errors.rent && <p className="text-danger">{errors.rent}</p>}
          </div>
          <div className="mt-2 mb-4">
            <label>
              Food expense <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="input"
              name="food"
              value={step2Inputs.food}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
            {errors.food && <p className="text-danger">{errors.food}</p>}
          </div>
          <div className="mt-2 mb-4">
            <label>
              Electricity bill <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="input"
              name="electricity"
              value={step2Inputs.electricity}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
            {errors.electricity && (
              <p className="text-danger">{errors.electricity}</p>
            )}
          </div>
          <div className="mt-2 mb-4">
            <label>
              gas bill <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="input"
              name="gas"
              value={step2Inputs.gas}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
            {errors.gas && <p className="text-danger">{errors.gas}</p>}
          </div>
          <div className="mt-2 mb-4">
            <label>other utilities</label>
            <input
              type="number"
              className="input"
              name="other"
              value={step2Inputs.other}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
          </div>
        </div>
        <div className="income-summary">
          <p>Total of living expense</p>
          <p>
            AED{" "}
            {parseInput(step2Inputs.rent) +
              parseInput(step2Inputs.food) +
              parseInput(step2Inputs.electricity) +
              parseInput(step2Inputs.gas) +
              parseInput(step2Inputs.other)}
          </p>
        </div>
      </div>
      {/*  */}
      <div className="step2-bg mt-2">
        <h1>Lifestyle expence</h1>
        <div className="steps-input-grid">
          <div className="mt-2 mb-4">
            <label>
              Travel/Fuel <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="input"
              name="travel"
              value={step2Inputs.travel}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
            {errors.travel && <p className="text-danger">{errors.travel}</p>}
          </div>
          <div className="mt-2 mb-4">
            <label>digital subscription</label>
            <input
              type="number"
              className="input"
              name="subscription"
              value={step2Inputs.subscription}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
          </div>
          <div className="mt-2 mb-4">
            <label>dining out</label>
            <input
              type="number"
              className="input"
              name="dining"
              value={step2Inputs.dining}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
          </div>
          <div className="mt-2 mb-4">
            <label>house help</label>
            <input
              type="number"
              className="input"
              name="houseHelp"
              value={step2Inputs.houseHelp}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
          </div>
          <div className="mt-2 mb-4">
            <label>outing</label>
            <input
              type="number"
              className="input"
              name="outing"
              value={step2Inputs.outing}
              onChange={handleStep2Inputs}
              onKeyDown={preventChange}
              onWheel={preventScroll}
            />
          </div>
        </div>
        <div className="income-summary">
          <p>Total of lifestyle expense</p>
          <p>
            AED{" "}
            {parseInput(step2Inputs.travel) +
              parseInput(step2Inputs.subscription) +
              parseInput(step2Inputs.dining) +
              parseInput(step2Inputs.houseHelp) +
              parseInput(step2Inputs.outing)}
          </p>
        </div>
      </div>
      <div className="text-end mt-2">
        <p className="text-danger fst-italic">
          {message !== "" && `Note: ${message}`}
        </p>
        <button className="button" onClick={showModal}>
          Proceed
        </button>
      </div>

      <div className={`modal-background ${modalVisible ? "active" : ""}`}>
        {user !== null ? (
          <div className="modal-container">
            <h2 className="mb-2">
              Summary of Income & Expenditure Information
            </h2>
            <div>
              <div className="income-summary-flex">
                <p>Monthly Take Home Income:</p>
                <p className="fw-bold">
                  <span className="fw-bold">AED</span>{" "}
                  {parseFloat(step2Inputs.salary) +
                    parseFloat(step2Inputs.businessIncome) +
                    parseFloat(step2Inputs.familySupport) +
                    parseFloat(step2Inputs.otherIncome)}
                </p>
              </div>
              <div className="income-summary-flex">
                <p>Unsecured outstanding:</p>
                <p className="fw-bold">
                  <span className="fw-bold">AED</span>{" "}
                  {summary.unsecuredOutstanding}
                </p>
              </div>
              <div className="income-summary-flex">
                <p>Living Expenses:</p>
                <p className="fw-bold">
                  <span className="fw-bold">AED</span>{" "}
                  {parseInput(step2Inputs.rent) +
                    parseInput(step2Inputs.food) +
                    parseInput(step2Inputs.electricity) +
                    parseInput(step2Inputs.gas) +
                    parseInput(step2Inputs.other)}
                </p>
              </div>
              <div className="income-summary-flex">
                <p>Lifestyle Expenses:</p>
                <p className="fw-bold">
                  <span className="fw-bold">AED</span>{" "}
                  {parseInput(step2Inputs.travel) +
                    parseInput(step2Inputs.subscription) +
                    parseInput(step2Inputs.dining) +
                    parseInput(step2Inputs.houseHelp) +
                    parseInput(step2Inputs.outing)}
                </p>
              </div>
            </div>

            {/* <div className="step2-summary">
              <div className="mb-4">
                <h2>Disposible Income (DI)</h2>
                <h2 className="primary-pill d-inline-block">
                  <span className="fw-bold">AED</span> {dispossibleIncome}
                </h2>
              </div>
              <div>
                <p className="fw-bold">Percentage of DI</p>
                <p>
                  <span className="primary-pill">{dipercent}%</span> of the
                  available income
                </p>
              </div>
            </div> */}
            <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
              <button
                className="red-button w-100"
                onClick={() => setModalVisible(false)}
              >
                cancel
              </button>
              <button className="button w-100" onClick={update}>
                Submit
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Step2;
