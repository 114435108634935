const importantPoints = [
  {
    point:
      "We will negotiate with all your creditors to set up the restructuring/settlement",
  },
  {
    point:
      "We offer our mobile user application portal so that you can keep up with updates with the restructuring/settlement.",
  },
  {
    point:
      "Our in-house lawyers will check all the restructuring/settlement documents, to ensure that the terms and conditions are as negotiated",
  },
  {
    point:
      "Referral program for enrolled customers, you earn when you refer new customers to us",
  },
];

export default importantPoints;
