import axios from "axios";
import React, { createContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AppContext = createContext();
const AppContextProvider = ({ children }) => {
  const url = "https://platform-api.tauruscreditmanagement.ae";
  const [step, setStep] = useState(1);
  const [user, setUser] = useState(null);
  //
  const navigate = useNavigate();
  const location = useLocation();
  const getUser = async () => {
    const smlPlatFromUserToken = localStorage.getItem("smlPlatFromUserToken");
    if (!smlPlatFromUserToken) {
      navigate("/login", { replace: true });
    } else {
      const res = await axios.get(`${url}/user`, {
        headers: {
          "Content-Type": "application/json",
          token: JSON.parse(smlPlatFromUserToken),
        },
      });
      if (res.data.success === true) {
        setUser(res.data.result);
        if (
          res.data.result.payment === false &&
          ["/", "/finances", "/creditors", "/profile"].includes(
            location.pathname
          )
        ) {
          navigate("/welcome", { replace: true });
        }
      } else if (res.data.success === false) {
        navigate("/login", { replace: true });
      }
    }
  };
  //
  const getToken = async () => {
    try {
      const res = await axios.get(`${url}/token`);
      return res.data.token[0].token;
    } catch (error) {
      console.log(error);
    }
  };
  //
  const preventChange = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  const preventScroll = (e) => {
    if (e.target.focus === true) {
      e.preventDefault();
    }
  };
  //
  const [plan, setPlan] = useState({
    months: 0,
    emi: 0,
    total: 0,
    settlement: false,
  });
  return (
    <AppContext.Provider
      value={{
        url,
        getUser,
        step,
        setStep,
        user,
        setUser,
        preventChange,
        preventScroll,
        plan,
        setPlan,
        getToken,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
